import React from "react";
import { Switch } from "react-router-dom";
import { RoleBasedRouting } from "./permission";
import UserProfile from "../pages/ProfilesPage/userProfile";
import TherapistProfile from "../pages/ProfilesPage/therapistProfile";
// import Chat from "../features/chat";

import Setting from "../pages/SettingsPage/setting";

// import User from "../views/viewsPatients/medicalVisit";
import PatientsTherapist from "../pages/ClinicalRecordsPage/components/patientsEvolution";
import SessionsTherapist from "../pages/ProtocolsPage";
import SessionsStudio from "../pages/SessionStudioPage/sessionsStudio";
import Calendar from "../features/calendar/calendar";
import CalendarRecomendados from "../features/calendar/calendar_recomendados";

import VariablesEvaluations from "../pages/CategoryEvaluationPage";

import ViewFlowTeleton from "../features/teleton/flow.teleton.view";

// Pages
import Users from "../pages/UsersPage";
import Library from "../pages/LibraryPage";
import MediaCategories from "../pages/MediaCategoriesPage";
import Evaluations from "../pages/EvaluationsPage";
import EvaluationsNew from "../pages/EvaluationsNewPage";
import Subsidiary from "../pages/SubsidiariesPage";
import Specialties from "../pages/SpecialtiesPage";
import TherapistTherapist from "../pages/ClinicalRecordsPage/";

import ClinicalData from "../pages/FollowUpPage";
import ClinicalDataPatients from "../pages/FollowUpPage/detail";


const SUPERADMIN = "superadmin";
const ADMINCLINIC = "admin-clinic";
const ADMIN = "admin";
const THERAPIST = "therapist";
// const PATIENT = "patient";

const BASIC_PERMISSION = [SUPERADMIN, ADMIN, ADMINCLINIC, THERAPIST];
const ADMIN_PERMISSION = [SUPERADMIN, ADMIN, ADMINCLINIC];
const ADMIN_CLINIC_PERMISSION = [SUPERADMIN, ADMIN, ADMINCLINIC];
const CLINIC_PERMISSION = [SUPERADMIN, ADMINCLINIC, THERAPIST];
// const PATIENT_PERMISSION = [PATIENT];

const Rutas = () => (
  <Switch>
    <RoleBasedRouting
      path="/"
      exact
      component={ClinicalData}
      roles={BASIC_PERMISSION}
      redirect="/treatment/schedule"
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/userProfile"
      exact
      component={UserProfile}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/therapistProfile"
      component={TherapistProfile}
    ></RoleBasedRouting>
    {/* <RoleBasedRouting path="/user" component={User}></RoleBasedRouting> */}
    {/* <RoleBasedRouting path="/chat" component={Chat}></RoleBasedRouting> */}
    <RoleBasedRouting
      path="/content/media-library"
      component={Library}
      roles={ADMIN_CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/content/media-categories"
      component={MediaCategories}
      roles={ADMIN_CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/content/evaluations"
      component={Evaluations}
      roles={ADMIN_CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/content/evaluations_new"
      component={EvaluationsNew}
      roles={ADMIN_CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/content/evaluation-categories"
      component={VariablesEvaluations}
      roles={ADMIN_CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/setting"
      component={Setting}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/clinicaldata"
      exact
      component={ClinicalData}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/clinicaldata/:id"
      component={ClinicalDataPatients}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>

    <RoleBasedRouting
      path="/clinical-records/:id"
      component={TherapistTherapist}
      roles={BASIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/clinical-records"
      component={TherapistTherapist}
      roles={BASIC_PERMISSION}
    ></RoleBasedRouting>

    <RoleBasedRouting
      path="/patient-evolution/:id/:event"
      component={PatientsTherapist}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/patient-evolution/:id"
      component={PatientsTherapist}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>

    <RoleBasedRouting
      path="/content/protocols/:protocol/:session"
      component={SessionsTherapist}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/content/protocols"
      component={SessionsTherapist}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/sessionsStudio/:id"
      component={SessionsStudio}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/treatment/planning/:id"
      exact
      component={Calendar}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/treatment/planning"
      exact
      component={Calendar}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/treatment/planning/:user/:data"
      component={CalendarRecomendados}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/resources/subsidiary"
      exact
      component={Subsidiary}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/resources/specialties"
      exact
      component={Specialties}
      roles={CLINIC_PERMISSION}
    ></RoleBasedRouting>

    <RoleBasedRouting
      path="/users"
      component={Users}
      roles={ADMIN_PERMISSION}
    ></RoleBasedRouting>
    <RoleBasedRouting
      path="/teleton/:id"
      component={ViewFlowTeleton}
    ></RoleBasedRouting>
  </Switch>
);

export default Rutas;
