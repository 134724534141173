import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  Divider,
  TextField,
  MenuItem,
  IconButton
} from "@mui/material";
import { Delete } from "@mui/icons-material"
import SelectionTemplate from "../SelectionTemplate";
import RangeTemplate from "../RangeTemplate";

const EvaluationActivityTemplate = ({
  activity,
  index,
  updateActivity,
  canDelete,
  deleteActivity
}) => {
  const { t } = useTranslation("evaluations_new");

  const SELECT_VALUES = [{
    text: t("template.activity.types.text"),
    value: "TEXT"
  },
  {
    text: t("template.activity.types.number"),
    value: "NUMBER"
  },
  {
    text: t("template.activity.types.single"),
    value: "SINGLE"
  },
  {
    text: t("template.activity.types.multiple"),
    value: "MULTIPLE"
  },
  {
    text: t("template.activity.types.range"),
    value: "RANGE"
  },
  {
    text: t("template.activity.types.slider"),
    value: "SLIDER"
  }]

  const [name, setName] = useState(activity.name)
  const [description, setDescription] = useState(activity.description)
  const [params, setParams] = useState({
    type: activity.type,
    params: activity.params
  })

  useEffect(() => {
    updateActivity({
      name,
      description,
      type: params.type,
      params: params.params
    }, index)
  }, [name, description, params])

  const sendDelete = () => {
    deleteActivity(index)
  }

  const updateTitle = (value) => {
    setName(value);
  }

  const updateDescription = (value) => {
    setDescription(value);
  }

  const updateType = (value) => {
    if (value === "TEXT") {
      setParams({
        type: value,
        params: [{ expectedValue: "string" }]
      })
    }
    if (value === "NUMBER") {
      setParams({
        type: value,
        params: [{ expectedValue: "number" }]
      })
    }
    if (value === "SINGLE" || value === "MULTIPLE") {
      setParams({
        type: value,
        params: [
          { name: "", expectedValue: "bool" },
          { name: "", expectedValue: "bool" },
        ]
      })
    }
    if (value === "RANGE" || value === "SLIDER") {
      setParams({
        type: value,
        params: [
          { name: 0, description: "MINIMUM", expectedValue: "number" },
          { name: 0, description: "MAXIMUM", expectedValue: "number" },
        ]
      })
    }
  }

  const updateParams = (values) => {
    setParams({
      type: params.type,
      params: values
    })
  }

  return (
    <>
      <Grid item>
        <Paper
          elevation={3}
          square={false}
        >
          <Grid container padding={2} sx={{ justifyContent: "flex-end" }}>
            <IconButton
              disabled={!canDelete}
              onClick={sendDelete}
            >
              <Delete />
            </IconButton>
          </Grid>
          <Grid container padding={2} spacing={2}>
            <Grid item md={8} sm={12}>
              <TextField
                variant="outlined"
                label={t("template.activity.title")}
                value={name}
                onChange={(e) => updateTitle(e.target.value)}
                fullWidth={true}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <TextField
                select
                label={t("template.activity.type")}
                value={params.type}
                onChange={(e) => updateType(e.target.value)}
                fullWidth={true}
              >
                {SELECT_VALUES.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid padding={2}>
            <TextField
              variant="outlined"
              label={t("template.activity.description")}
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
              fullWidth={true}
            />
          </Grid>
          <Grid padding={2}>
            <Divider />
          </Grid>
          <Grid padding={2}>
            {params.type === "SINGLE" &&
              <SelectionTemplate
                values={params.params}
                updateParams={updateParams}
              />}
            {params.type === "MULTIPLE" &&
              <SelectionTemplate
                values={params.params}
                updateParams={updateParams}
              />}
            {params.type === "RANGE" &&
              <RangeTemplate
                values={params.params}
                updateParams={updateParams}
              />}
            {params.type === "SLIDER" &&
              <RangeTemplate
                values={params.params}
                updateParams={updateParams}
              />}
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </>
  )
}

export default EvaluationActivityTemplate;