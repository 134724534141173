import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
} from "@mui/material";

const RangeTemplate = ({ values, updateParams }) => {
  const { t } = useTranslation("evaluations_new");

  const [params, setParams] = useState(values)

  useEffect(() => {
    updateParams(params)
  }, [params])

  const updateRange = (value, index) => {
    const paramCopy = [...params];
    paramCopy[index].name = value;
    setParams(paramCopy)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          label={t("template.activity.minimumValue")}
          value={params[0].name}
          onChange={(e) => updateRange(e.target.value, 0)}
          fullWidth={true}
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          label={t("template.activity.maximumValue")}
          value={params[1].name}
          onChange={(e) => updateRange(e.target.value, 1)}
          fullWidth={true}
          type="number"
        />
      </Grid>
    </Grid>
  )
}

export default RangeTemplate;