import React, { useState } from "react";
import axios from "axios";
import { getUserId } from "../../lib/router";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import {
  Add,
  CheckCircleOutline,
  ErrorOutline
} from "@mui/icons-material"
import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import FullScreenModal from "../../components/FullScreenModal"
import LoadingDialog from "../../components/LoadingDialog";
import GenericDialog from "../../components/GenericDialog";
import EvaluationActivityTemplate from "./components/EvaluationActivityTemplate";

const Evaluations = () => {
  const { t } = useTranslation("evaluations_new");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const [activityKey, setActivityKey] = useState(1);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activities, setActivities] = useState([
    {
      key: 0,
      name: "",
      description: "",
      type: "",
      params: []
    }
  ]);

  const resetState = () => {
    setActivityKey(1)
    setName("")
    setDescription("")
    setActivities([{
      key: 0,
      name: "",
      description: "",
      type: "",
      params: []
    }])
  }

  const openDialog = () => { setDialogOpen(true) }

  const closeDialog = () => { setDialogOpen(false) }

  const addActivity = () => {
    setActivities([...activities, {
      key: activityKey,
      name: "",
      description: "",
      type: "",
      params: []
    }])
    setActivityKey(activityKey + 1)
  }

  const updateActivity = (activity, index) => {
    const activityCopy = activities.map((item, i) => {
      if (index === i) {
        return { ...activity, key: item.key };
      }
      return item
    })
    setActivities(activityCopy)
  }

  const deleteActivity = (index) => {
    const activityCopy = Array.from(activities);
    activityCopy.splice(index, 1)
    setActivities(activityCopy)
  }

  const saveEvaluation = async () => {
    setLoading(true);
    const body = {
      name,
      description,
      therapistId: getUserId().userId,
      activities
    };
    try {
      await axios.post("/evaluations_new", body);
      setSuccessDialog(true)
      resetState()
    } catch (error) {
      setErrorDialog(true)
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid>
          {/* We likely want to eventually replace this entire component */}
          <CardLayoutPlatform
            title={t("title")}
          >
            {/* Display list of evaluation templates */}
          </CardLayoutPlatform>
        </Grid>
        <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
          }}
          color="primary"
          aria-label="add"
          onClick={openDialog}
        >
          <Add />
        </Fab>
      </Box>
      <FullScreenModal
        open={dialogOpen}
        handleClose={closeDialog}
        title={t("create")}
        submitButton={t("save")}
        onSubmit={saveEvaluation}
        Content={
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid
              container
              item
              direction={"column"}
              padding={2}
              spacing={2}
              md={8}
              xs={11}
            >
              <Grid item>
                <TextField
                  variant="outlined"
                  label={t("template.name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label={t("template.description")}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              {
                activities.map((item, index) => (
                  <EvaluationActivityTemplate
                    key={item.key}
                    activity={item}
                    index={index}
                    updateActivity={updateActivity}
                    canDelete={activities.length > 1}
                    deleteActivity={deleteActivity}
                  />
                ))
              }
              <Grid
                item
                container
                sx={{
                  justifyContent: "flex-end"
                }}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={addActivity}
                  >
                    {t("template.add")}
                  </Button>
                </Grid>
              </Grid>
            </Grid >
          </Grid>
        }
      />
      <LoadingDialog
        open={loading}
        message={t("saving")}
      />
      <GenericDialog
        open={successDialog}
        handleClose={() => setSuccessDialog(false)}
        icon={<CheckCircleOutline />}
        message={t("success")}
      />
      <GenericDialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        icon={<ErrorOutline />}
        message={t("error")}
      />
    </>
  )
}

export default Evaluations;